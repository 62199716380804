$shadow-1  : 20px 20px 40px rgba(175, 175, 175, 0.16);
$shadow-2  : 0 28px 54px rgba(0, 0, 0, 0.12);
$shadow-3  : 0 4px 55px rgba(0, 0, 0, 0.08);
$shadow-4  : 0 30px 40px rgba(255, 75, 54, 0.3);
$shadow-5  : 0 0 60px rgba(0, 0, 0, 0.25);
$shadow-6  : 0 3px 10px rgba(0, 0, 0, 0.09);
$shadow-7  : 4px 0 54px rgba(0, 0, 0, 0.05);
$shadow-8  : 0 4px 56px rgba(22, 22, 22, 0.1);
$shadow-9  : -1px 1px 4px rgba(22, 22, 22, 0.16);
$shadow-10  : 0 1px 4px rgba(0, 0, 0, 0.1);
$shadow-11  : 0 4px 56px rgba(0, 0, 0, 0.12);




$gr-shadows: (
  1           : $shadow-1,
  2           : $shadow-2,
  3           : $shadow-3,
  4           : $shadow-4, 
  5           : $shadow-5, 
  6           : $shadow-6, 
  7           : $shadow-7, 
  8           : $shadow-8, 
  9           : $shadow-9, 
  10          : $shadow-10, 
  11          : $shadow-11, 
);
