@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&family=Karla:wght@400;700&display=swap');
//-------------------------
//--- Theme Typography
//-------------------------
$enable-responsive-font-sizes: true;
$font-family-base:            'Heebo', sans-serif;
$font-family-2:            'Karla', sans-serif;

//custom font-family
$btn-font-family: $font-family-base;
$heading-font-family: $font-family-2;
$paragraph-font-family: $font-family-base;


$multiple-font-family: true;
$font-families: (
  "1": $font-family-base,
  "2": $font-family-2,
  
);


$headings-font-weight:        700;
// $headings-line-height:        1.5;

//- Display Sizes

$display1-size:               6rem; // About 96px
$display2-size:               5rem; // About 80px
$display3-size:               3.75rem; // About 60px
$display4-size:               3.125rem; // About 50px


//- Font Sizes 
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                13px;

$h1-font-size:                3rem; //About 48px
$h2-font-size:                2.5rem; //About 40px
$h3-font-size:                2.125rem;  //About 34px
$h4-font-size:                1.5rem; //About 24px
$h5-font-size:                1.3125rem; //About 21px
$h6-font-size:                1rem; //About 16px



$text-base:                  1.125rem; //18px


$fsize: (
  1:(
    font-size:13px,
    line-height:1.631
  ),
  2:(
    font-size:15px,
    line-height:1.33
    ),
  3:(
    font-size:16px,
    line-height:1.625,
    
  ), 
  4:(
    font-size:17px,
    line-height:1.65,
    ),
  5:(
    font-size:18px,
    line-height:1.77,
    
  ),
  6:(
    font-size:21px,
    line-height:1.3,
    letter-spacing:-1px
    ),
  7:(
    font-size:24px,
    line-height:1.25,
    letter-spacing:-1px
    ),
  8:(
    font-size:36px,
    line-height:1.2,
    letter-spacing:-2px
    ),
  9:(
    font-size:45px,
    line-height:1.15,
    letter-spacing:-3px
    ),
  10:(
    font-size:48px,
    line-height:1.21,
    letter-spacing:-3px
    ),
  11:(
    font-size:58px,
    line-height:1.121
    ),
  12:(
    font-size:80px,
    line-height:1.075,
    letter-spacing:-4px
    )
);