// Allows for customizing button radius independently from global border radius
$border-radius-xs:            3px;           //custom
$border-radius-sm:            0.3125rem;    //5px (bootstrap provided variable)
$border-radius-md:            0.5rem;      //8px custom
$border-radius:               0.625rem;   //10px (bootstrap provided variable)
$border-radius-lg:            0.625rem;  //10px (bootstrap provided variable)
$border-radius-xl:            1.25rem;  //20px  custom




//--------------------------------------------------------------------------------------------------------------
//--> use this format in the array to generate custom border radius class. EG: gr-rounded-{your-key}
//-------------------------------------------------------------------------------------------------------------
// 1 : 5px,
// 2 : 8px,
// 3 : 10px,
// 4 : 15px, //class name rounded-value
$gr-border-radius:(
  xs : $border-radius-xs,
  md : $border-radius-md,
  xl: $border-radius-xl,
  0: 0px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
  12: 12px,
  15: 15px,
  16: 16px,
  20: 20px,
  58: 58px,
  
);
$gr-border-width:(
  1: 1px,
  2: 2px,
  3: 3px,
);

//border-width-4 //class name border-width-value



.border-bottom-radius-5{
  border-radius: 0px 0px 5px 5px !important;
}
.border-bottom-radius-10{
  border-radius: 0px 0px 10px 10px;
}
.border-top-radius-10{
  border-radius: 10px 10px 0px 0px;
}

.feature-btn-border{
  border:1px solid $red-orange
}

.department-menu{
  border-right: 1px solid rgba(181, 181, 181, 0.2);
  height: max-content;
}

.share-btn-border{
  border: 1px solid #747474;
}

.border-none{
  border: 0 !important;
}