
@mixin dark-theme-mode{
  --bg:                 #000;
  --bg-2:               #000;
  --color-headings:     #fff;
  --color-texts:        #fff;
   --color-texts-2:     #000;
   --color-texts-3:     #fff;
  --border-color:       rgba(255,255,255,.7);
  --border-color-2:     rgba(255,255,255,.7);
  --border-color-3:     rgba(255,255,255,.7);
  --border-color-4:     rgba(255,255,255,.7);
  --border-color-5:     rgba(255,255,255,.7);
 

  
}
@mixin light-theme-mode{
  --bg:                  #fff;
  --bg-2:                #fff;
  --color-headings:      #161616;
  --color-texts:         #747474;
  --color-texts-2:       #fff;
  --color-texts-3:       #000;
  --border-color:        rgba(181, 181, 181, 0.2);
  --border-color-2:      rgba(22, 22, 22, 0.12);
  --border-color-3:     rgba(62, 73, 243, 0.1);
  --border-color-4:     rgba(24, 33, 77, 0.12);
  --border-color-5:     rgba(29, 41, 63, 0.1);
 
 
}

// #FCFDFE, #F4F7FA, #F8F8F8, #ECF2F7, #FDFDFF, 


@mixin dark-mode {
  [data-theme='dark'] & ,.dark-mode-texts & {
      @content;
  }
}
@mixin light-mode {
  [data-theme='dark'] & ,.dark-mode-texts & {
      @content;
  }
}
