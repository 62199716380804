$white:    #fff ;
$black:    #000;
$royal-blue: #3E49F3;
$mantis: #91CD6D;
$red-orange: #FF4B36; 
$gray: #848282;
$boulder: #747474;
$emperor: #555353;
$cod-gray: #161616;
$cod-gray-light : #1D1C1C;
$white-lilac: #F1F4FA;
$nobel: #b5b5b5;
$danube : #6D8DCD;
$monza : #D20028;
$jaffa : #F2994A;
$royalblue : #2F80ED;
$athens-gray: #ECEFF4;
$sunshade : #FFAA2B;
$christine : #EC610A;
$medium-purple: #9B51E0;
$selago: #F8FAFE;
$cod-gray-deep: #191919;
$chateaugreen: #41C34E;
$catskillwhite: #F9FAFC;
$mineshaft: #232323;
$mystic: #DFE3EC;
$white-ash: #FDFDFD;
$tango: #F37121;
$cloud-burst: #1D293F;
$crusta: #F9813A;
$heliotrope: #8E59FF;
$brilliant-rose: #F653A2;
$robin-egg-blue: #00BCD4;
$lima: #81B214;
$jungle-green: #27AE60;



$primary:     $royal-blue;
$green:       green;
$secondary:   blue;
$indigo:      indigo;



$headings-color: var(--color-headings);
$body-bg:                   var(--bg);
$body-color:                var(--color-texts);
$border-color:              var(--border-color);


// Links
// Style anchor elements.

$link-color:                              $primary;
$link-hover-color:                        darken($link-color, 15%);
$link-decoration:                         none;
$link-hover-decoration:                   none;



//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //  Maps
//+========================================================================================

$theme-colors: () ;
$theme-colors: map-merge(
  (
    "primary"     :   $primary,
    "secondary"   :   $secondary,
  ),
  $theme-colors
);

// Background and  text Colors
$colors : (
  "primary" : $primary,
  "black" :$black,
  "royal-blue" :$royal-blue,
  "mantis" :$mantis,
  "red-orange" :$red-orange,
  "gray" :$gray,
  "boulder" :$boulder,
  "emperor" :$emperor,
  "cod-gray" :$cod-gray,
  "white-lilac" :$white-lilac,
  "nobel" :$nobel,
  "danube" :$danube,
  "monza"   :$monza,
  "jaffa"  :$jaffa,
  "royalblue" :$royalblue,
  "athens-gray" :$athens-gray,
  "sunshade"    :$sunshade,
  "christine"   :$christine,
  "medium-purple" :$medium-purple,
  "selago" :$selago,
  "cod-gray-light" :$cod-gray-light,
  "cod-gray-deep" :$cod-gray-deep,
  "chateaugreen" :$chateaugreen,
  "catskillwhite" :$catskillwhite,
  "mineshaft" :$mineshaft,
  "mystic" :$mystic,
  "white-ash" :$white-ash,
  "tango" :$tango,
  "cloud-burst" :$cloud-burst,
  "crusta" :$crusta,
  "heliotrope" :$heliotrope,
  "brilliant-rose" :$brilliant-rose,
  "robin-egg-blue" :$robin-egg-blue,
  "lima" :$lima,
  "jungle-green" :$jungle-green,
);

// For button color
$btn-colors:(
  "black"     :   $black,
  "red-orange" :$red-orange,
);


// For border color

$border-colors:(
  "primary" : $primary,
  "black" :$black,
  "royal-blue" :$royal-blue,
  "mantis" :$mantis,
  "red-orange" :$red-orange,
  "gray" :$gray,
  "boulder" :$boulder,
  "emperor" :$emperor,
  "cod-gray" :$cod-gray,
  "white-lilac" :$white-lilac,
  "nobel" :$nobel,
  "danube" :$danube,
  "monza"   :$monza,
  "jaffa"  :$jaffa,
  "royalblue" :$royalblue,
  "athens-gray" :$athens-gray,
  "sunshade"    :$sunshade,
  "christine"   :$christine,
  "medium-purple" :$medium-purple,
  "selago" :$selago,
  "cod-gray-light" :$cod-gray-light,
  "cod-gray-deep" :$cod-gray-deep,
  "chateaugreen" :$chateaugreen,
  "catskillwhite" :$catskillwhite,
  "mineshaft" :$mineshaft,
  "mystic" :$mystic,
  "white-ash" :$white-ash,
  "tango" :$tango,
  "cloud-burst" :$cloud-burst,
  "crusta" :$crusta,
  "heliotrope" :$heliotrope,
  "brilliant-rose" :$brilliant-rose,
  "robin-egg-blue" :$robin-egg-blue,
  "lima" :$lima,
  "jungle-green" :$jungle-green,

);

//-------------------------
//-- Opacity Values class opacity-value
//-------------------------
$gr-opacity: ();
$gr-opacity: map-merge(
  (
    visible : 1,
    1 : .1,
    2 : .2,
    4 : .4,
    6: .6,
    7: .7,
    8 : .8,
    9: .9,
    15 : .15,
    25: .25,
  ),
  $gr-opacity
);


